// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    height: 100px;
}
.language-selection {
    padding: 25px 0;
}
.intro-header {
    background: #004494;
    color: #fff;
    margin: 0 0 6rem;
    padding: 1rem 0 1rem;
    position: relative;
}
.intro-header:after {
    content: '';
    background: #4a90e2;
    height: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}
.subtitle {
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 2rem;
    font-size: 1.4rem;
}
#footer-extra {
    background: #F5F5F5;
    margin: 5rem 0 0;
    padding: 1.5rem 0;
}
#footer-extra a {
    color: #26527D;
}

#footer-links {
    background: #004494;
    padding: 3rem 0;
}
#footer-links ul {
    padding: 0;
}
#footer-links ul li {
    display: inline-block;
    margin-right: 4rem;
    margin-bottom: 0;
}
#footer-links a {
    color: #fff;
    padding: 0 25px 0 0;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
}

@media (max-width: 767px) {
    #footer-links a {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,OAAO;AACX;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".logo {\r\n    height: 100px;\r\n}\r\n.language-selection {\r\n    padding: 25px 0;\r\n}\r\n.intro-header {\r\n    background: #004494;\r\n    color: #fff;\r\n    margin: 0 0 6rem;\r\n    padding: 1rem 0 1rem;\r\n    position: relative;\r\n}\r\n.intro-header:after {\r\n    content: '';\r\n    background: #4a90e2;\r\n    height: 1rem;\r\n    position: absolute;\r\n    bottom: 0;\r\n    width: 100%;\r\n    left: 0;\r\n}\r\n.subtitle {\r\n    font-weight: 400;\r\n    line-height: 140%;\r\n    margin-bottom: 2rem;\r\n    font-size: 1.4rem;\r\n}\r\n#footer-extra {\r\n    background: #F5F5F5;\r\n    margin: 5rem 0 0;\r\n    padding: 1.5rem 0;\r\n}\r\n#footer-extra a {\r\n    color: #26527D;\r\n}\r\n\r\n#footer-links {\r\n    background: #004494;\r\n    padding: 3rem 0;\r\n}\r\n#footer-links ul {\r\n    padding: 0;\r\n}\r\n#footer-links ul li {\r\n    display: inline-block;\r\n    margin-right: 4rem;\r\n    margin-bottom: 0;\r\n}\r\n#footer-links a {\r\n    color: #fff;\r\n    padding: 0 25px 0 0;\r\n    font-size: 15px;\r\n    font-weight: 500;\r\n    text-decoration: underline;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    #footer-links a {\r\n        display: block;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
