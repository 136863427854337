import React, { useState, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import './Countries.css';
import { useTranslation} from 'react-i18next';
import { Dropdown, Form, Button, Grid, Header, Segment, Divider, Message, Icon, Label } from 'semantic-ui-react'

const Countries = ({countries}) => {
  
    const {t} = useTranslation();

    const navigate = useNavigate();
    const navigateToExperts = () => {        
        navigate(`/experts/${selectedCountry}`);
    }

    const [selectedCountry, setSelectedCountry] = useState([]);    
    const getSelectedCountry = async(e) =>{
      try {
        if (e){
          setSelectedCountry(e.target.value);
        }        
      } catch(error){
        console.log(error);
      }
    }
    useEffect(()=>{
      getSelectedCountry();
  
    },[])

    const listOfCountries = countries?.map((c) => {
      return {value: c.code, text: c.name, flag: c.code.toLowerCase()}
    })
    const onCountryChange = (e, data) => {
      setSelectedCountry(data.value)
    }

    const countryURLInformation = () => {
      const filteredCountries = countries.filter(country=>selectedCountry == country.code)
      return filteredCountries.length == 1 ? filteredCountries[0].information : "" 
    } 

    const countryName = () => {
      const filteredCountries = countries.filter(country=>selectedCountry == country.code)
      return filteredCountries.length == 1 ? filteredCountries[0].name : "" 
    } 
    
    return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
        <Header as={'h4'}>{t('main.header')}</Header>
        <br/>
        <Form.Field>
            <label htmlFor="country-select">{t('lookingFor')}</label><br/>
            <Dropdown id="country-select"
              placeholder={t('select.pleaseSelect')}
              fluid
              clearable
              search
              selection
              options={listOfCountries || []}
              onChange={onCountryChange}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row >
        <Grid.Column>    
        {selectedCountry.length>0 ? (
        <div>
          <Message info>
            {/* <i>{t('dataOrigin')}</i><br/>
            <Divider/><br/> */}
            {t('moreInformationAbout')} {countryName()} {t('canBeFound')} <a href={countryURLInformation()} target="_blank" title="Country Information">{t('here')}</a>.
            <Divider/>
            {t('moreInformationSources')} <a href={require('../../../src/files/FINDEX II Demo IT Tool User Guide.pdf')} target="_blank" title="Demo IT Tool User Guide">{t('userGuide')}</a>.    
          </Message>
          <Button id="next-button" size="large" primary onClick={navigateToExperts}>{t('next')}</Button>
        </div>
        ):(<p/>)}       
        </Grid.Column>
      </Grid.Row>
    </Grid>
    )
}

export default Countries
