import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import api from '../../api/axiosConfig';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Divider, Button, Grid, Header, Flag, Accordion, Icon, Input, Pagination, Popup, Message, List, Table, Card, CardContent, TableHeaderCell, TableRow, TableHeader, TableBody, TableCell, Label, Search, TableFooter, Segment } from 'semantic-ui-react'
import './Experts.css';
import i18next, { t } from "i18next";
import _ from 'lodash';
import SearchButtons  from "../searchButtons/SearchButtons";

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'MORE_DATA_NEEDED':
      return { ...state, loading: false, value: action.query, results: [{title: "", description: "Please type at least 3 characters..."}] }  
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection }

    default:
      throw new Error()
  }
}  
const initialState = {
  loading: false,
  results: [],
  value: '',
}

const Experts = ({ }) => {

  const PAGE_SIZE = 10

  const { t } = useTranslation()
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(`/`);
  }

  const { country } = useParams()

  const [countryByCode, setCountryByCode] = useState("")
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [company, setCompany] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [selectedSpokenLanguage, setSelectedSpokenLanguage] = useState(null)
  const [selectedFindexNomenclature, setSelectedFindexNomenclature] = useState("")
  const [selectedNationalNomenclature, setSelectedNationalNomenclature] = useState("")
  const [experts, setExperts] = useState([])
  const [expertsToDisplay, setExpertsToDisplay] = useState([])
  const [nomenclaturesFindex, setNomenclaturesFindex] = useState([])
  const [nomenclaturesNational, setNomenclaturesNational] = useState([])
  const [nomenclaturesNationalLocal, setNomenclaturesNationalLocal] = useState([])
  const [nomenclaturesNationalAll, setNomenclaturesNationalAll] = useState([])
  const [spokenLanguages, setSpokenLanguages] = useState([])
  const [sortingFilter, setSortingFilter] = useState(null)
  const [orderFilter, setOrderFilter] = useState(null)
  // ------------------------------------------------- SEARCH -----------------------------------------------------

  // const source = _.times(5, () => ({
  //   title: "Title is a big title - a big title here please, Title is a big title",
  //   description: "Description",
  //   // price: 10
  // }))
  // console.log(source);
  

  const [state, dispatch] = React.useReducer(exampleReducer, initialState)
  const { loading, results, value } = state
  const timeoutRef = React.useRef()
 

// ------------------------------------------------- SEARCH -----------------------------------------------------

  const sortingOptions = [
    {
      key: 'lastName', text: 'Surname', value: 'lastName'
    },
    {
      key: 'city', text: 'City', value: 'city'
    }
  ]

  const orderOptions = [
    {
      key: 'ascending', text: t('ascending'), value: 'ascending'
    },
    {
      key: 'descending', text: t('descending'), value: 'descending'
    }
  ]

  const buildQueryParams = () => {
    let args = []

      if (selectedFindexNomenclature != null && selectedFindexNomenclature != "") { // search also by findexNomenclature if category
        args.push(`findexNomenclature=${selectedFindexNomenclature}`)
      }
      //
      if (selectedNationalNomenclature != null && selectedNationalNomenclature != "") { // search only by nationalNomenclature
          args.push(`nationalNomenclature=${selectedNationalNomenclature}`)
      }
      //
      if (lastName.length > 0) {
        args.push(`lastName=${lastName}`)
      }
      //
      if (firstName.length > 0) {
        args.push(`firstName=${firstName}`)
      }
      //
      if (company.length > 0) {
        args.push(`company=${company}`)
      }
      //
      if (city.length > 0) {
        args.push(`city=${city}`)
      }
      //
      if (postalCode.length > 0) {
        args.push(`postalCode=${postalCode}`)
      }
      //
      if (selectedSpokenLanguage != null && selectedSpokenLanguage != "") {
        args.push(`spokenLanguage=${selectedSpokenLanguage}`)
      }
      //
      if (sortingFilter != null && sortingFilter != ""){
        args.push(`sortBy=${sortingFilter}`)
      }
      //
      if (orderFilter != null && orderFilter != ""){
        args.push(`orderBy=${orderFilter}`)
      }
      //
      const queryParams = args.join('&')
    
      return queryParams;
  }
  const queryParams = useMemo(() => {
    return buildQueryParams()
  },[selectedFindexNomenclature, selectedNationalNomenclature, lastName, firstName, company, postalCode, city, selectedSpokenLanguage, sortingFilter, orderFilter])


  const getCountryByCode = async (c) => {
    try {
      const response = await api.get(`/api/v1/countries/${c}`)
      setCountryByCode(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCountryByCode(country)
  }, [country])
  //////////////////////////////////////////////////////////////////////////////


  const getExperts = async(c) => {
    try {
      const response = await api.get(`/api/v1/experts/search/${c}`)
      setExperts(response.data)
      setExpertsToDisplay(response.data.slice(0, PAGE_SIZE))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getExperts(country)
  }, [country])
  //////////////////////////////////////////////////////////////////////////////

  const getNomenclaturesFindex = async (selectedNationalNomenclature) => {
    try {
      let endpoint = `/api/v1/nomenclature_findex`

      if (selectedNationalNomenclature != null && selectedNationalNomenclature != "") {
        endpoint = `/api/v1/nomenclature_findex/national=${selectedNationalNomenclature}`
      }
      const response = await api.get(`${endpoint}`)    

      const listOfNomenclaturesFindex = response.data?.map((fnom) => {
        return { value: fnom.id, text: fnom.specialty }
      })

      setNomenclaturesFindex(listOfNomenclaturesFindex || [])
      if (listOfNomenclaturesFindex!= null && listOfNomenclaturesFindex.length == 1) {
        setSelectedFindexNomenclature(listOfNomenclaturesFindex[0].value)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getNomenclaturesFindex(selectedNationalNomenclature);
  }, [selectedNationalNomenclature])

  //////////////////////////////////////////////////////////////////////////////

    const getNomenclaturesNational = async (country, selectedFindexNomenclature) => {
      try {

        let endpoint = `/api/v1/nomenclature_national/${country}`

        if (selectedFindexNomenclature != null && selectedFindexNomenclature != "") {
          endpoint = `/api/v1/nomenclature_national/${country}/${selectedFindexNomenclature}`
        }
        const response = await api.get(`${endpoint}`)
        
        const listOfNomenclaturesNational = response.data?.map((nom) => {
          return { value: nom.id, text: nom.specialty}
        })
        setNomenclaturesNational(listOfNomenclaturesNational || [])
      
        
        ////// local language ////////
        const listOfNomenclaturesNationalLocal = response.data?.map((nom) => {
          return { value: nom.id, text: nom.specialty_local}
        })
        setNomenclaturesNationalLocal(listOfNomenclaturesNationalLocal || [])

        if (listOfNomenclaturesNational!= null && listOfNomenclaturesNational.length == 1) {
          setSelectedNationalNomenclature(listOfNomenclaturesNational[0].value)
        }

      } catch (error) {
        console.log(error)
      }
    } 

    useEffect(() => {
      getNomenclaturesNational(country, selectedFindexNomenclature)
    }, [country, selectedFindexNomenclature])  

  //////////////////////////////////////////////////////////////////////////////
  const getNomenclaturesNationalAll = async () => {
    try {
      let endpoint = `/api/v1/nomenclature_national`

      const response = await api.get(`${endpoint}`)    

      const listOfNomenclaturesNationalAll = response.data?.map((nom) => {
        return { title: nom.specialty_local, description: '', value: nom.findexId, key: nom.id, flag:nom.country.code.toLowerCase() }
      })

      setNomenclaturesNationalAll(listOfNomenclaturesNationalAll || [])

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getNomenclaturesNationalAll();
  }, [])

 

  ///////////////////////////////////////////////////////////////////////////////

  const getSpokenLanguages = async () => {
    try {
      const response = await api.get("/api/v1/spokenLanguages")

      const listOfSpokenLanguages = response.data?.map((lang) => {
        return { key: lang.code, value: lang.code, text: lang.language }
      })
      
      setSpokenLanguages(listOfSpokenLanguages || [])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSpokenLanguages();
  }, [])
  //////////////////////////////////////////////////////////////////////////////

  const searchByFilters = async () => {

    try {
      let endpoint = `/api/v1/experts/${country}/search`
    
      const response = await api.get(`${endpoint}?${queryParams}`)
      setExperts(response.data)
      setExpertsToDisplay(response.data.slice(0, PAGE_SIZE))
    } catch (e) {
      console.log(e)
    }
  }

  const clearFilters = async () => {
    try {
      setFirstName('')
      setLastName('')
      setCompany('')
      setCity('')
      setPostalCode('')
      setSelectedFindexNomenclature(null)
      setSelectedNationalNomenclature(null)
      dispatch({ type: 'CLEAN_QUERY' }) // Search field
      setSelectedSpokenLanguage(null)
      //
      setSortingFilter(null)
      setOrderFilter(null)
      // reload all experts 
      getExperts(country)
    } catch (e) {
      console.log(e)
    }
  }

  const onFindexNomenclatureChange = (e, data) => {
    setSelectedFindexNomenclature(data.value)    
  }

  const onNationalNomenclatureChange = (e, data) => {
    setSelectedNationalNomenclature(data.value)
  }

  const handleSearchChange = React.useCallback((e, data) => {
    if (data?.value?.length < 4) {
      dispatch({ type: 'MORE_DATA_NEEDED', query: data.value })
      return
    }
    clearTimeout(timeoutRef.current)
    dispatch({ type: 'START_SEARCH', query: data.value })
    
    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' })
        return
      }

      const re = new RegExp(_.escapeRegExp(data.value), 'i')
      const isMatch = (result) => re.test(result.title)

      dispatch({
        type: 'FINISH_SEARCH',
        results: _.filter(nomenclaturesNationalAll, isMatch),
      })
    }, 300)
  }, [nomenclaturesNationalAll])
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const handleResultSelect = (e, { result }) => {
      dispatch({ type: 'UPDATE_SELECTION', selection: result.title })
      setSelectedFindexNomenclature(result.value.id)       
  }
  const resultRenderer = ({ title, flag}) => <><Flag name={flag}/> {title}</>
  
  const onLastNameChange = (e, data) => {
    setLastName(data.value)
  }

  const onFirstNameChange = (e, data) => {
    setFirstName(data.value)
  }

  const onCompanyChange = (e, data) => {
    setCompany(data.value)
  }

  const onCityChange = (e, data) => {
    setCity(data.value)
  }

  const onPostalCodeChange = (e, data) => {
    setPostalCode(data.value)
  }

  const onSpokenLanguageChange = (e, data) => {
    setSelectedSpokenLanguage(data.value)
  }

  const handlePaginationChange = (e, { activePage }) => {
    setExpertsToDisplay(experts.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE))
  }

  const onSortingChange = (e, data) => {
    if (orderFilter == null){
      setOrderFilter("ascending");
    }
    setSortingFilter(data.value);
  }

  const onOrderChange = (e, data) => {
    setOrderFilter(data.value);
  }

  const isFilterButtonDisabled = ()  => {
    return (
            !selectedFindexNomenclature &&
            !selectedNationalNomenclature &&
            !selectedSpokenLanguage && 
            lastName.length == 0 &&
            firstName.length == 0 && 
            company.length == 0 && 
            city.length == 0 && 
            postalCode.length == 0 &&
            !sortingFilter &&
            !orderFilter);
  }  

  return (
    <Grid stackable>
      <Button style={{backgroundColor: 'transparent'}} onClick={navigateToHome}> <Icon name='home' /> {t('main.home')}</Button>
      <Grid.Row className="language-info">
        <Grid.Column textAlign="left" width={12}>
          <Header as='h4'>{t('searchIn')} <Fragment>&nbsp;</Fragment><Flag name={country.toLowerCase()}></Flag>{countryByCode.name}</Header>
          <Header as='h6'><i>{t('dataOrigin')}</i></Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width={4}>
          <Button size="small" primary onClick={navigateToHome}>{t('changeCountry')}</Button>
        </Grid.Column>
      </Grid.Row>
   
      <Grid.Row>
        <Grid.Column width={6} className="first-column">
        <SearchButtons disabled={isFilterButtonDisabled()} onSearch={searchByFilters} onReset={clearFilters}/> 
          {/* Filters */}
          <Header as='h3'>{t('refineResults')}</Header>
            <Card fluid>
            <CardContent extra>
                {t('nomenclatureDescription')}
              </CardContent>
              <CardContent>
                <Form.Field>
                  <label htmlFor="findex-search">{t('lookingForExpertise')}</label>
                  <Search id="findex-search"
                    fluid
                    loading={loading}
                    placeholder={t('typeFieldOfExpertise')}
                    onResultSelect={handleResultSelect}
                    onSearchChange={handleSearchChange}
                    resultRenderer={resultRenderer}
                    results={results}
                    value={value}
                  />
                </Form.Field>
                <br/>
                <br/>
                <Form.Field>
                  <label htmlFor="findex-nomenclature">{t('findexNomenclature')}</label>
                  <Dropdown id="findex-nomenclature"
                    placeholder={t('select.selectFindexNomenclature')}
                    fluid
                    clearable
                    search
                    selection
                    options={nomenclaturesFindex || []}
                    onChange={onFindexNomenclatureChange}
                    value={selectedFindexNomenclature}
                  />
                </Form.Field>
                <br/>
                <Form.Field>
                  <label htmlFor="national-nomenclature">{t('nationalNomenclature')}</label>
                  <Dropdown id="national-nomenclature"
                    placeholder={t('select.selectNationalNomenclature')}
                    fluid
                    clearable
                    search
                    selection
                    options={nomenclaturesNational || []}
                    onChange={onNationalNomenclatureChange}
                    value={selectedNationalNomenclature}
                  />
                </Form.Field>
                <br/>
                <Form.Field>
                  <label htmlFor="national-nomenclature-local">{t('nationalNomenclatureLocal')}</label>
                  <Dropdown id="national-nomenclature-local"
                    placeholder={t('select.selectNationalNomenclature')}
                    fluid
                    clearable
                    search
                    selection
                    options={nomenclaturesNationalLocal || []}
                    onChange={onNationalNomenclatureChange}
                    value={selectedNationalNomenclature}
                  />
                </Form.Field>
                <br/>
              </CardContent>
            </Card>
   
 
          <Form.Field>
            <label htmlFor="lastName">{t('lastName')}</label><br/>
            <Input type="text"
              id="lastName"
              name="lastName"
              fluid
              onChange={onLastNameChange}
              value={lastName}
              placeholder={t('select.typeInSurname')}/>
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="firstName">{t('firstName')}</label><br/>
            <Input type="text"
              id="firstName"
              name="firstName"
              fluid
              onChange={onFirstNameChange}
              value={firstName}
              placeholder={t('select.typeInName')}/>
          </Form.Field>
          <br />
          <Form.Field>
            <label htmlFor="company">{t('company')}</label><br/>
            <Input type="text"
              id="company"
              name="company"
              fluid
              onChange={onCompanyChange}
              value={company}
              placeholder={t('select.typeInCompanyName')} />
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="city">{t('city')}</label><br/>
            <Input type="text"
              id="city"
              name="city"
              fluid
              onChange={onCityChange}
              value={city}
              placeholder={t('select.typeInCity')}/>
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="postalCode">{t('postalCode')}</label><br/>
            <Input type="text"
              id="postalCode"
              name="postalCode"
              fluid
              onChange={onPostalCodeChange}
              value={postalCode}
              placeholder={t('select.typeInPostalCode')}/>
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="spoken-language">{t('spokenLanguage')}</label><br/>
            <Dropdown id="spoken-language"
              placeholder={t('select.selectSpokenLanguage')}
              fluid
              clearable
              // multiple
              search
              selection
              options={spokenLanguages || []}
              onChange={onSpokenLanguageChange}
              value={selectedSpokenLanguage}
            />
          </Form.Field>
          <br/><br/>
          <Divider/>
          <Form.Field>
            <label htmlFor="sortBy">{t('sortBy')}</label><br/>
            <Dropdown id="sortBy"
              placeholder={t('select.selectSortingParameter')}
              fluid
              selection
              onChange={onSortingChange}
              options={sortingOptions}
              value={sortingFilter}
            />
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="orderBy">{t('order')}</label><br/>
            <Dropdown id="orderBy"
              placeholder={t('select.selectOrderParameter')}
              fluid
              selection
              onChange={onOrderChange}
              options={orderOptions}
              value={orderFilter}
            />
          </Form.Field>
          <br/><br/>
          <SearchButtons disabled={isFilterButtonDisabled()} onSearch={searchByFilters} onReset={clearFilters}/> 
        </Grid.Column>

        <Grid.Column width={10} className="second-column">
          {/* Results */}
          <Grid columns='two'>
          {( experts.length > 0 && orderFilter == null ) &&
            <Grid.Row>
            <Message attached='bottom' info>
                <Icon name='warning circle'/>
                {t('defaultOrdering')}
              </Message>
              <br/>
            </Grid.Row>
            }
            <Grid.Row>
              <Grid.Column>              
                <Header as={'h4'}>{experts.length} {experts.length == 1? t('recordFound') : t('recordsFound')} 
                  <Header.Subheader>
                    {expertsToDisplay.length} {t('outOf')} {experts.length}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              {experts.length > 0 &&
              <Grid.Column textAlign="right">   
                <Link to={`${process.env.REACT_APP_BACKEND}experts/${country}/search/pdf?${queryParams}`}>
                <Popup content={t('hoverResults')}
                  trigger={<Button className="download-btn" as='span' size="small" labelPosition='left'>
                  <Icon name='download' />
                  {t('downloadResults')}
                  </Button>}>
                </Popup>                                  
                </Link>    
                <a
                  href={require('../../../src/files/Questionnaire.docx')}
                  download="Questionnaire"
                  target="_blank"
                  rel="noreferrer"
                >
                <Popup content={t('hoverQuestionnaire')}
                  trigger={<Button
                  className="download-btn" as='span' size="small" labelPosition='left' href="">
                  <Icon name='download' />
                  {t('downloadQuestionnaire')}
                  </Button>}>
                </Popup>                
                </a>                
            
              </Grid.Column>
              }
            </Grid.Row>
          </Grid>  
          <br/>

          {experts.length > 0 &&
            <ExpertsResults experts={expertsToDisplay}/>
          }

          {experts.length > 0 &&
            <Pagination
              defaultActivePage={1}
              pointing
              secondary
              onPageChange={handlePaginationChange}
              totalPages={Math.ceil(experts.length / 10)}
            />
          }

        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}



const ExpertsResults = ({ experts }) => {

  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return experts?.map((expert, index) => (
    <div key={expert.id}>
      <Accordion styled>       
        <Accordion.Title active={activeIndex === index} index={index} onClick={handleClick}>
          <Icon name='dropdown'/>
          {expert.lastName + ' ' + expert.firstName}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index} className="expert-details">
         {/* Create two columns for information */}
          <Grid columns='two' stackable> 
            <Grid.Row>
              <Grid.Column width={10}>
                <List> 
                  <List.Item>
                    <List.Icon name='mail' />
                    <List.Content>
                      <a href={"mailto:" + expert.email}>{expert.email.length > 0 ? expert.email : "-"}</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='phone' />
                    <List.Content>
                      {expert.phone.length > 0 ? expert.phone : "-"}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='world' />
                    <List.Content>
                      {expert.website.length > 0 ? expert.website : "-"}
                    </List.Content>
                  </List.Item>             
                  <List.Item>
                    <List.Icon name='marker' />
                    <List.Content>{expert.address}, {expert.city}, {expert.postalCode}</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='users' />
                    <List.Content>{expert.company.length > 0 ? expert.company : "-"}</List.Content>
                  </List.Item> 
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <List>
                  <List.Item>
                    <List.Header>{t('spokenLanguages')}</List.Header>
                    <List.Content>{expert.spokenLanguages?.map((a) => {
                      return <List.Item key={a.code}>{a.language}</List.Item>
                    })}</List.Content> 
                  </List.Item>
                  {expert.registrationBody?.length > 0 &&
                  <List.Item>
                    <List.Header>{t('registrationBody')} </List.Header>
                    <List.Content>{expert.registrationBody} {expert.registrationBodyCity.length > 0 ? "(" + expert.registrationBodyCity + ")" : ""}</List.Content>
                  </List.Item>
                  }
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>         

          <Divider horizontal/>
          {expert.nomenclatureNationalList?.length > 0 &&    
          <Segment raised>
             <Label as='a' color='blue' ribbon>{t('fieldOfExpertise')}</Label>
            <Table unstackable inverted color={'blue'} selectable>
            <TableHeader>
            <TableRow>
                {/* <TableHeaderCell>{t('findexNomenclature')} ({t(`languages.${i18next.language}`)})</TableHeaderCell> */}
                <TableHeaderCell>{t('findexNomenclature')} (English)</TableHeaderCell>
                <TableHeaderCell>{t('nationalNomenclature')} (English)</TableHeaderCell>
                <TableHeaderCell>{t('nationalNomenclature')} ({expert.country.language})</TableHeaderCell>            
              </TableRow>
            </TableHeader>
            <TableBody>
              {expert.nomenclatureNationalList?.map((a) => {
              return <TableRow key={a.id}>
                <TableCell>{a.findexId.specialty}</TableCell>
                <TableCell>{a.specialty} <i><br/>{a.specialization? t('registeredSpecializations') + ": " + a.specialization : ""}</i></TableCell>
                <TableCell>{a.specialty_local}<i><br/>{a.specialization_local? t('registeredSpecializations') + ": " + a.specialization_local : ""}</i></TableCell>           
              </TableRow>
              })}
            </TableBody>
          </Table>
          
          </Segment>
          }
        
        <Link to={`${process.env.REACT_APP_BACKEND}experts/expert/${expert.id}/pdf`}>
          <div style={{ textAlign: 'right' }}>
          <Button
            className="download-btn" floated="right" as='span' size="small" labelPosition='left' href="">
            <Icon name='save' />
            {t('downloadExpertDetails')}
            </Button>
          </div>
        </Link>
         
        </Accordion.Content>
      </Accordion>
      <br />
    </div>
  ))
}

export default Experts
