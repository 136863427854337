import Countries from "../countries/Countries";
import { useEffect, useState} from 'react';
import api from '../../api/axiosConfig';

const Home = ({}) => {

    const [countries, setCountries] = useState();

    const getCountries = async() =>{
  
      try {
          const response = await api.get("/api/v1/countries");
          setCountries(response.data);  
      } catch(error){
        console.log(error);
      }  
    }
  
    useEffect(()=>{
      getCountries();  
    },[])

    return (
        <Countries countries={countries}/> 
    )
}

export default Home
